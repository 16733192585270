export default [{
    path: '/messages',
    name: 'messages',
    component: () =>
        import ('@/views/modules/contacts/contacts-list/ContactsList.vue'),
    meta: {
        resource: 'ACL',
        pageTitle: 'Mensajes',
        breadcrumb: [{
            text: 'Lista de mensajes',
            active: true
        }]
    }
}]
import Vue from 'vue';
import { ToastPlugin, ModalPlugin, BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';


import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// Bootstrap 
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// 3rd party plugins
import '@/libs/acl';
import '@/libs/portal-vue';
import '@/libs/toastification';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');

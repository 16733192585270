export default [
    {
        path: '/blogs',
        name: 'blogs',
        component: () =>
            import ('@/views/modules/blogs/blogs-list/BlogsList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Blogs',
            breadcrumb: [{
                text: 'Lista de blogs',
                active: true
            }]
        }
    }, 
    {
        path: '/blogs/create',
        name: 'blogs-create',
        component: () =>
            import ('@/views/modules/blogs/blogs-store/BlogStore.vue'),
        meta: {
            pageTitle: 'Blogs',
            breadcrumb: [{
                text: 'Nuevo blog',
                active: true
            }]
        }
    },
    {
        path: '/blogs/:id/edit',
        name: 'blogs-edit',
        component: () =>
            import ('@/views/modules/blogs/blogs-edit/BlogsEdit.vue'),
        meta: {
            pageTitle: 'Blogs',
            breadcrumb: [{
                text: 'Editar blog',
                active: true
            }]
        }
    }
]
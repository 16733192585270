export default [{
        path: '/discountcoupons',
        name: 'discountcoupons',
        component: () =>
            import ('@/views/modules/discountcoupons/discountcoupons-list/DiscountCouponsList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Cupones de descuento',
            breadcrumb: [{
                text: 'Lista de cupones de descuento',
                active: true
            }]
        }
    },
    {
        path: '/discountcoupon/create',
        name: 'discountcoupon-create',
        component: () =>
            import ('@/views/modules/discountcoupons/discountcoupons-store/DiscountCouponStore.vue'),
        meta: {
            pageTitle: 'Cupones de descuento',
            breadcrumb: [{
                text: 'Nuevo cupón de descuento',
                active: true
            }]
        }
    },
    {
        path: '/discountcoupon/:id/edit',
        name: 'discountcoupon-edit',
        component: () =>
            import ('@/views/modules/discountcoupons/discountcoupons-edit/DiscountCouponsEdit.vue'),
        meta: {
            pageTitle: 'Cupones de descuento',
            breadcrumb: [{
                text: 'Editar cupón de descuento',
                active: true
            }]
        }
    }
]
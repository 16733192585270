export default [{
    path: '/sales',
    name: 'sales',
    component: () =>
        import ('@/views/modules/sales/sales-list/SalesList.vue'),
    meta: {
        resource: 'ACL',
        pageTitle: 'Ventas',
        breadcrumb: [{
            text: 'Listado de ventas',
            active: true
        }]
    }
}, {
    path: '/sale/:id/details',
    name: 'sale-details',
    component: () =>
        import ('@/views/modules/sales/sales-details/SalesDetails.vue'),
    meta: {
        pageTitle: 'Ventas',
        breadcrumb: [{
            text: 'Detalle de la Venta',
            active: true
        }]
    }
}, ]
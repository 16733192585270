export default [{
    path: '/login',
    name: 'login',
    component: () =>
        import ('@/views/pages/authentication/Login.vue'),
    meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true
    }
}]
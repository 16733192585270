export default [{
        path: '/products',
        name: 'products',
        component: () =>
            import ('@/views/modules/products/products-list/ProductsList.vue'),
        meta: {
            resource: 'ACL',
            pageTitle: 'Productos',
            breadcrumb: [{
                text: 'Lista de productos',
                active: true
            }]
        }
    },
    {
        path: '/product/create',
        name: 'product-create',
        component: () =>
            import ('@/views/modules/products/products-list/ProductListAddNew.vue'),
        meta: {
            pageTitle: 'Productos',
            breadcrumb: [{
                text: 'Nuevo producto',
                active: true
            }]
        }
    },
    {
        path: '/product/:id/edit',
        name: 'product-edit',
        component: () =>
            import ('@/views/modules/products/products-edit/ProductsEdit.vue'),
        meta: {
            pageTitle: 'Productos',
            breadcrumb: [{
                text: 'Editar producto',
                active: true
            }]
        }
    },
    {
        path: '/product/comments',
        name: 'product-comments',
        component: () =>
            import ('@/views/modules/comments/comments-list/CommentsList.vue'),
        meta: {
            pageTitle: 'Productos',
            breadcrumb: [{
                text: 'Comentarios',
                active: true
            }]
        }
    }
]
export default [{
    path: '/subscribers',
    name: 'subscribers',
    component: () =>
        import ('@/views/modules/subscribers/subscribers-list/SubscribersList.vue'),
    meta: {
        resource: 'ACL',
        pageTitle: 'Suscriptores',
        breadcrumb: [{
            text: 'Lista de suscriptores',
            active: true
        }]
    }
}]
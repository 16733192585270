import axios from '@axios';
import _ from 'underscore';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchProducts(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/products`, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchProduct(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/product/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteProduct(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/product/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}